import styled from "@emotion/styled"
import * as palette from "../../../const/palette"

const Header = styled.header`
  background: ${palette.PRIMARY};
  margin-bottom: 0;
  padding: 0.5rem 0;
  @media (min-width: 48em) {
    padding: 1.5rem 0;
  }
`

export default Header
