/** @jsx jsx */
import { jsx, Flex, Box, Heading, Text } from "theme-ui"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as palette from "../../const/palette"

const MyP = (props) => <p {...props} />
const MyA = (props) => (
  <a
    sx={{ color: `${palette.SECONDARY}`, "&:hover": { color: "#fff" } }}
    {...props}
  />
)

const components = {
  p: MyP,
  a: MyA,
}

const Announcements = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query GetAnnouncements {
          allMdx(
            filter: { fileAbsolutePath: { regex: "/announcements/" } }
            sort: { order: DESC, fields: [frontmatter___dateAnnouncement] }
          ) {
            edges {
              node {
                id
                body
                frontmatter {
                  dateHide
                  publish
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const todayTimestamp = new Date().getTime()
        let announcements = data.allMdx.edges.filter(
          (edge) => Date.parse(edge.node.frontmatter.dateHide) > todayTimestamp
        )
        return (
          <>
            {announcements.length > 1 ? (
              <Flex
                sx={{
                  p: 2,
                  background: `${palette.HIGHLIGHT}`,
                  color: "#fff",
                }}
              >
                <Box
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  <Heading
                    sx={{
                      mb: 2,
                    }}
                  >
                    Aktuelles:
                  </Heading>
                  <ul>
                    {announcements.map(({ node }) => {
                      return (
                        <React.Fragment key={node.id}>
                          {node.frontmatter.publish && (
                            <li>
                              <MDXProvider components={components}>
                                <MDXRenderer>{node.body}</MDXRenderer>
                              </MDXProvider>
                            </li>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </ul>
                </Box>
              </Flex>
            ) : null}
          </>
        )
      }}
    />
  )
}

export default Announcements
