import styled from "@emotion/styled"
import * as palette from "../../../const/palette"

const Footer = styled.footer`
  background: ${palette.PRIMARY};
  color: #fff;
  min-height: 200px;
  margin-top: 2rem;
  padding: 27px 0;
`

export default Footer
