import { Link } from "gatsby";
import SEO from "../../../../src/components/SEO";
import Wide from "../../../../src/components-styled/Wide";
import { Flex, Box } from "theme-ui";
import PictureSet from "../../../../src/components/PictureSet";
import imgSet08 from "../../../../src/imgsets/imgset08";
import * as React from 'react';
export default {
  Link,
  SEO,
  Wide,
  Flex,
  Box,
  PictureSet,
  imgSet08,
  React
};