import styled from "@emotion/styled"

const Logo = styled.span`
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
`

export default Logo
