import { Link } from "gatsby";
import Layout from "../../../../src/components/Layout";
import SEO from "../../../../src/components/SEO";
import { Flex, Box } from "theme-ui";
import Wide from "../../../../src/components-styled/Wide";
import PictureSet from "../../../../src/components/PictureSet";
import imgSet11 from "../../../../src/imgsets/imgset11";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  Flex,
  Box,
  Wide,
  PictureSet,
  imgSet11,
  React
};