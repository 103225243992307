import "normalize.css"
import "./layout.css"
import "tippy.js/dist/tippy.css"
import "fontsource-titillium-web/400.css"
import "fontsource-titillium-web/700.css"
// import "fontsource-ubuntu/400.css"
// import "fontsource-ubuntu/700.css"
import "fontsource-commissioner/400.css"
import "fontsource-commissioner/600.css"
import React from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"
import { StaticQuery, graphql } from "gatsby"
import { Box } from "theme-ui"
import PageHeader from "../PageHeader"
import PageFooter from "../PageFooter"
import Banner from "../Banner"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faThumbtack, faAt } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

import theme from "../../gatsby-plugin-theme-ui"
import * as palette from "../../const/palette"

library.add(faFacebook, faTwitter, faInstagram, faThumbtack, faAt)

let browser = {}
// Wrap the require in check for window
if (typeof window !== `undefined`) {
  const bowser = require("bowser")
  browser = bowser.getParser(window.navigator.userAgent).getBrowser()
} else {
  browser.name = "undefined"
  browser.version = ""
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            date
            version
          }
        }
      }
    `}
    render={(data) => (
      <Box>
        <Global
          styles={css`
            html {
              font-size: calc(${theme.fontSizes[0]} / 16 * 100%);
            }
            a {
              color: ${palette.PRIMARY};
              text-decoration: none;
            }

            a:hover {
              color: ${palette.HEADING};
            }

            footer a {
              color: #fff;
              text-decoration: none;
            }

            footer a:hover {
              color: ${palette.HEADING};
            }
          `}
        ></Global>
        <PageHeader />
        <Banner />
        <Box
          sx={{
            mx: "auto",
            px: "8px",
            maxWidth: "1000px",
            minHeight: "70vh",
          }}
        >
          {children}
        </Box>
        <PageFooter
          version={data.site.siteMetadata.version}
          changeDate={data.site.siteMetadata.date}
          browserName={browser.name}
          browserVersion={browser.version}
        />
        <CookieConsent
          buttonText="OK"
          cookieName="hgncNetlifyApp"
          sameSite="strict"
          cookieSecurity={true}
          debug={false}
        >
          Diese Website verwendet Cookies für Verwaltungszwecke. Mit der Nutzung
          dieser Website erklärst du dich damit einverstanden.
        </CookieConsent>
      </Box>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

// cookieSecurity={true}
