import genImgSet from "../components/PictureSet/genImgSet"

// const generateImgObject = require("./generateImgObject").default
// this is for Webpack to allow for dynamically requiring resources
// https://webpack.js.org/guides/dependency-management/#require-context
// require.context() cannot take variables

//             RELATIVE PATH TO IMAGES FROM HERE  ↓
// const requireFromContext = require.context("../img/", true, /00\.jpg$/)
//                                                 CHANGE HERE  ↑
const requireFromContext = require.context("../img/", true, /09\.jpg$/)
// CHANGE HERE    ↓
// let imgName = "00"
let imgName = "09"

const imgStatic = {
  imgPath: "./", // relative to require.context
  imgName: imgName,
  extension: ".jpg",
  blurResolution: "0100",
}

// Setup for image. Do not include blurResolution
// "resolution": "aspectRatio"
// {maxWidth: aspect}
const imgParams = {
  "0480": "2x1",
  1200: "3x1",
  1920: "4x1",
}

let imgSet = genImgSet(imgParams, imgStatic, requireFromContext)

export default imgSet
