import * as palette from "../const/palette"

const heading = {
  color: "text",
  fontFamily: "heading",
  lineHeight: "heading",
  fontWeight: "heading",
}

export const theme = {
  breakpoints: ["321px", "480px", "768px", "1024px"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "Titillium Web, sans-serif",
    heading: "Titillium Web, sans-serif",
    monospace: "monospace",
  },
  //           0,  1,  2,  3,  4,  5,  6,  7,  8
  // fontSizes: [16, 48, 32, 24, 20, 16, 16, 13, 40],
  // fontSizes: [17, 48, 32, 25, 21, 17, 17, 14, 40],
  fontSizes: [19, 48, 34, 28, 23, 19, 19, 14, 50],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    heading: palette.HEADING,
    text: palette.TEXT,
    background: "#fff",
    primary: palette.PRIMARY,
    secondary: palette.SECONDARY,
    accent: palette.ACCENT,
    highlight: palette.HIGHLIGHT,
    muted: palette.MUTED,
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
  },
  heading: { lineHeight: "heading" },
  // This is for MDX elements
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      ...heading,
      fontSize: [8, 8, 8, 8, 1],
      color: "heading",
      mt: 4,
      mb: 3,
    },
    h2: {
      ...heading,
      fontSize: 2,
      color: "primary",
    },
    h3: {
      ...heading,
      fontSize: 3,
    },
    h4: {
      ...heading,
      fontSize: 4,
    },
    h5: {
      ...heading,
      fontSize: 5,
    },
    h6: {
      ...heading,
      fontSize: 6,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 0,
    },
    a: {
      color: "primary",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
    hr: {
      borderTop: `2px solid ${palette.HIGHLIGHT}`,
      borderBottom: `0px solid ${palette.HIGHLIGHT}`,
      width: "100%",
    },
    ul: { mt: 0, mb: 3 },
    ol: { mt: 0, mb: 3 },
  },
}

export default theme
