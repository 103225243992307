import styled from "@emotion/styled"

const HeaderSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 auto;
  max-width: 1000px;
}
`

export default HeaderSection
