/** @jsx jsx */
import { jsx, Flex, Box, Text } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import FormEmailLazy from "../FormEmailLazy"
import Footer from "./styled/Footer"
import FooterSection from "./styled/FooterSection"
import LegalSection from "./styled/LegalSection"
import Link from "./styled/LinkFooterStyled"
import A from "./styled/A"

const PageFooter = ({ version, changeDate, browserName, browserVersion }) => (
  <Footer>
    <FooterSection>
      <Box sx={{ width: ["320", "466px"] }}>
        <a href="mailto:hgnc@hgnc.de">hgnc@hgnc.de</a>
        <br />
        <FormEmailLazy />
      </Box>
      <Box sx={{ width: "240px" }}>
        <Link to="/">&copy; hgnc</Link>
        <br />
        <a href="https://ulrich-anders.eu">&copy; Prof. Dr. Ulrich Anders</a>
        <br />
        Version: {version}
        <br />
        Änderung: {changeDate}
        <br />
        Browser: {browserName} {browserVersion}
        <br />
        <Flex>
          <Text sx={{ pr: 1 }}>Speed tests:</Text>
          <Box>
            <a href="https://developers.google.com/speed/pagespeed/insights/?url=hgncev.onrender.com">
              PageSpeed
            </a>
            <br />
            <a href="https://tools.keycdn.com/speed">keycdn</a>
            <br />
            <a href="https://gtmetrix.com/">GTmetrix</a>
            <br />
            <a href="https://www.webpagetest.org/">WebpageTest</a>
            <br />
            <a href="https://testmysite.io/">testmysite.io</a>
            {/* <br />
            <a href="https://tools.pingdom.com/">pingdom</a> */}
          </Box>
        </Flex>
        {/* <br />
        <Link to="/admin">ADMIN</Link> */}
      </Box>
    </FooterSection>
    <LegalSection sx={{ mt: 4 }}>
      <Box>
        <Link to="/impressum">Impressum</Link>
        {" · "}
        <Link to="/datenschutz">Datenschutz</Link>
        {/* {" · "} <a href="javascript:gaOptout();">Google Analytics deaktivieren</a> */}
      </Box>
    </LegalSection>
  </Footer>
)

PageFooter.propTypes = {
  browserName: PropTypes.string,
  browserVersion: PropTypes.string,
  changeDate: PropTypes.string,
}

export default PageFooter
