import styled from "@emotion/styled"
import * as palette from "../../../const/palette"

const NavSection = styled.nav`
  padding: 0.5rem 0;
  width: 100%;
  color: #fff;
  background: ${palette.SECONDARY};
`

export default NavSection
