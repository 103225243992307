import { Link } from "gatsby";
import SEO from "../../../../src/components/SEO";
import Wide from "../../../../src/components-styled/Wide";
import { Flex, Box } from "theme-ui";
import PictureSet from "../../../../src/components/PictureSet";
import imgSet20 from "../../../../src/imgsets/imgset20";
import logoExist from "../../../../src/img/0400/logoEXIST.jpg";
import logoRFH from "../../../../src/img/0400/logoRFH.png";
import logoSpoHo from "../../../../src/img/0400/logoSpoHo.png";
import logoTHKoeln from "../../../../src/img/0400/logoTHKoeln.png";
import logoUzK from "../../../../src/img/0400/logoUzK.jpg";
import AlinaLey from "../../../../src/img/0480/1x1/hgnc_Alina-Ley.jpg";
import DrClaudiaCombrink from "../../../../src/img/0480/1x1/hgnc_Dr-Claudia-Combrink.jpg";
import DrMaritaMehlstaeble from "../../../../src/img/0480/1x1/hgnc_Dr-Marita-Mehlstaeubl.jpg";
import DrSabineGrubenbecher from "../../../../src/img/0480/1x1/hgnc_Dr-Sabine-Grubenbecher.jpg";
import DrSabineSternstein from "../../../../src/img/0480/1x1/hgnc_Dr-Sabine-Sternstein.jpg";
import EstherDenOuden from "../../../../src/img/0480/1x1/hgnc_Esther-den-Ouden.jpg";
import FloraCarlhoff from "../../../../src/img/0480/1x1/hgnc_Flora-Carlhoff.jpg";
import LisaKoehler from "../../../../src/img/0480/1x1/hgnc_Lisa-Koehler.jpg";
import MarcKley from "../../../../src/img/0480/1x1/hgnc_Marc-Kley.jpg";
import ProfDrChristianSchwens from "../../../../src/img/0480/1x1/hgnc_Prof-Dr-Christian-Schwens.jpg";
import ProfDrKaiBuehler from "../../../../src/img/0480/1x1/hgnc_Prof-Dr-Kai-Buehler.jpg";
import ProfDrKaiThuerbach from "../../../../src/img/0480/1x1/hgnc_Prof-Dr-Kai-Thuerbach.jpg";
import ProfDrKlausBecker from "../../../../src/img/0480/1x1/hgnc_Prof-Dr-Klaus-Becker.jpg";
import ProfDrMarcProkop from "../../../../src/img/0480/1x1/hgnc_Prof-Dr-Marc-Prokop.jpg";
import ProfDrRainerMinz from "../../../../src/img/0480/1x1/hgnc_Prof-Dr-Rainer-Minz.jpg";
import ProfDrUlrichAnders from "../../../../src/img/0480/1x1/hgnc_Prof-Dr-Ulrich-Anders.jpg";
import ProfJanReckerPhD from "../../../../src/img/0480/1x1/hgnc_Prof-Jan-Recker-PhD.jpg";
import RamonaKoch from "../../../../src/img/0480/1x1/hgnc_Ramona-Koch.jpg";
import SophiaHaski from "../../../../src/img/0480/1x1/hgnc_Sophia-Haski.jpg";
import * as React from 'react';
export default {
  Link,
  SEO,
  Wide,
  Flex,
  Box,
  PictureSet,
  imgSet20,
  logoExist,
  logoRFH,
  logoSpoHo,
  logoTHKoeln,
  logoUzK,
  AlinaLey,
  DrClaudiaCombrink,
  DrMaritaMehlstaeble,
  DrSabineGrubenbecher,
  DrSabineSternstein,
  EstherDenOuden,
  FloraCarlhoff,
  LisaKoehler,
  MarcKley,
  ProfDrChristianSchwens,
  ProfDrKaiBuehler,
  ProfDrKaiThuerbach,
  ProfDrKlausBecker,
  ProfDrMarcProkop,
  ProfDrRainerMinz,
  ProfDrUlrichAnders,
  ProfJanReckerPhD,
  RamonaKoch,
  SophiaHaski,
  React
};