import styled from "@emotion/styled"
import * as palette from "../../../const/palette"

const Ribbon = styled.div`
  background: ${palette.HIGHLIGHT};
  margin: 0;
  color: #fff;
`

export default Ribbon
