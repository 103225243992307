import styled from "@emotion/styled"
import { Link } from "gatsby"

const NavItem = styled(Link)`
  font-size: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
`

export default NavItem
