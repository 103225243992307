import { Link } from "gatsby";
import SEO from "../../../../src/components/SEO";
import Wide from "../../../../src/components-styled/Wide";
import { Flex, Box, Text } from "theme-ui";
import PictureSet from "../../../../src/components/PictureSet";
import imgSet14 from "../../../../src/imgsets/imgset14";
import * as palette from "../../../../src/const/palette";
import * as React from 'react';
export default {
  Link,
  SEO,
  Wide,
  Flex,
  Box,
  Text,
  PictureSet,
  imgSet14,
  palette,
  React
};