import { Link } from "gatsby";
import SEO from "../../../../src/components/SEO";
import Wide from "../../../../src/components-styled/Wide";
import { Flex, Box, Text } from "theme-ui";
import Announcements from "../../../../src/components/Announcements";
import PictureSet from "../../../../src/components/PictureSet";
import imgSet01 from "../../../../src/imgsets/imgset01";
import number01 from "../../../../src/img/svg/number01.svg";
import number02 from "../../../../src/img/svg/number02.svg";
import number03 from "../../../../src/img/svg/number03.svg";
import number04 from "../../../../src/img/svg/number04.svg";
import number05 from "../../../../src/img/svg/number05.svg";
import number06 from "../../../../src/img/svg/number06.svg";
import * as React from 'react';
export default {
  Link,
  SEO,
  Wide,
  Flex,
  Box,
  Text,
  Announcements,
  PictureSet,
  imgSet01,
  number01,
  number02,
  number03,
  number04,
  number05,
  number06,
  React
};