/** @jsx jsx */
import { jsx, Text } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Ribbon from "./styled/Ribbon"
import BannerSection from "./styled/BannerSection"
import * as palette from "../../const/palette"

const Banner = () => {
  return (
    <Ribbon>
      <BannerSection>
        <Text sx={{ textAlign: "center" }}>
          {/* ★★★★★&nbsp;&nbsp;&nbsp;NEU: Willkommen auf unserer neuen
          Webseite.&nbsp;&nbsp;&nbsp;★★★★★ */}
          ★★★★★&nbsp;&nbsp;&nbsp;Diese Webseite ist in Entwicklung und
          reflektiert nicht den aktuellen Stand. Bitte besuche{" "}
          <a
            href="https://hgnc.de"
            sx={{
              color: "#fff",
              "&:hover": {
                color: palette.SECONDARY,
              },
            }}
          >
            https://hgnc.de
          </a>
          .&nbsp;&nbsp;&nbsp;★★★★★
        </Text>
      </BannerSection>
    </Ribbon>
  )
}

export default Banner
