import { Link } from "gatsby";
import SEO from "../../../../src/components/SEO";
import Wide from "../../../../src/components-styled/Wide";
import { Flex, Box } from "theme-ui";
import FormMatchLazy from "../../../../src/components/FormMatchLazy";
import PictureSet from "../../../../src/components/PictureSet";
import imgSet07 from "../../../../src/imgsets/imgset07";
import * as React from 'react';
export default {
  Link,
  SEO,
  Wide,
  Flex,
  Box,
  FormMatchLazy,
  PictureSet,
  imgSet07,
  React
};