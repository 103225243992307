import styled from "@emotion/styled"
import { css } from "@emotion/core"

// let dynamicStyle = props =>
//   css`
//     color: ${props.color};
//   `

let dynamicStyle = props => {
  if (props.mediaStrings) {
    const length = props.mediaStrings.length
    let mediaStrings = []
    for (let l = 0; l < length; l++) {
      mediaStrings.push(css`
        @media ${props.mediaStrings[l]} {
          padding-bottom: ${props.paddingsInPercent[l]}%;
        }
      `)
    }
    return mediaStrings
  }
}

/**
 * PictureFrame is surrounding a Picture. The purpose is to fix the margins
 * and also the aspect ratio. The aspect ratio is fixed with the well know
 * trick to set `height: 0` and to then determine the `padding-bottom` in
 * percentages. Which makes the whole content box a percentage of the width.
 */
const PictureFrame = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  height: 0;
  padding-bottom: ${props =>
    (props.paddingsInPercent && props.paddingsInPercent[0] + "%") || "100%"};
  ${dynamicStyle}
`

export default PictureFrame
