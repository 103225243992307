/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState } from "react"
import { Divider, Heading, Text, Image, Box } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Header from "./styled/Header"
import HeaderSection from "./styled/HeaderSection"
import Section from "../Section"
import Logo from "./styled/Logo"
import Nav from "./styled/Nav"
import NavSection from "./styled/NavSection"
import NavItem from "./styled/NavItem"
import Link from "./styled/HeaderLinkStyled"
import logoHgnc from "../../img/svg/logoHgnc.svg"
import logoGateway from "../../img/svg/logoGatewayInverse.svg"

import * as palette from "../../const/palette"

const PageHeader = () => {
  const [logo, setLogo] = useState(0)
  return (
    <>
      <Header>
        <HeaderSection>
          <Link to="/">
            {logo === 0 ? (
              <Logo>
                <Image
                  src={logoHgnc}
                  sx={{ mb: [0, 0, 3] }}
                  css={{
                    width: "60px",
                    height: "80px",
                    display: "inline-block",
                  }}
                  width="61"
                  height="150"
                  alt="Logo hgnc"
                />
                <span>
                  <Text
                    sx={{ fontSize: [2, 2, 8], mt: 0 }}
                    css={{
                      display: "inline-block",
                    }}
                  >
                    hgnc
                  </Text>
                  <Text
                    sx={{
                      display: ["none", "none", "inline"],
                      fontSize: [5, 4, 8],
                    }}
                  >
                    {" · "}
                  </Text>
                  <Text
                    sx={{ fontSize: [5, 4, 3, 8], mt: 0 }}
                    css={{
                      display: "inline-block",
                    }}
                  >
                    hochschulgründernetz cologne e.V
                    <span onClick={() => setLogo((logo + 1) % 2)}>.</span>
                  </Text>
                </span>
              </Logo>
            ) : (
              <div>
                <Logo>
                  <Image
                    src={logoGateway}
                    sx={{ ml: 2, mt: 2, mr: 3, flex: "0 0 auto" }}
                    css={{
                      width: ["150px", "104px"],
                      display: "inline-block",
                    }}
                    width="215"
                    height="150"
                    alt="Logo Gateway"
                  />
                  <span sx={{ fontFamily: "Commissioner" }}>
                    <Text
                      sx={{
                        fontFamily: "Commissioner",
                        fontWeight: "600",
                        fontSize: [5, 4, 3, 8],
                        mt: 0,
                      }}
                      css={{
                        display: "inline-block",
                      }}
                    >
                      Gateway Gründungsnetz e.V
                      <span
                        sx={{
                          fontFamily: "inherit",
                          fontWeight: "inherit",
                          fontSize: "inherit",
                        }}
                        onClick={() => setLogo((logo + 1) % 2)}
                      >
                        .
                      </span>
                    </Text>
                    <Text
                      sx={{
                        fontSize: [7, 6],
                      }}
                    >
                      Wir unterstützen Startups aus Hochschulen und Wissenschaft
                    </Text>
                  </span>
                </Logo>
              </div>
            )}
          </Link>
        </HeaderSection>
        <HeaderSection sx={{ flexDirection: "row-reverse" }}>
          <Box sx={{ mr: 2 }}>
            <a
              href="mailto:hgnc@hgnc.de"
              sx={{
                color: "#fff",
                "&:hover": { color: `${palette.SECONDARY}` },
              }}
            >
              <FontAwesomeIcon icon={["fas", "at"]} />
            </a>
            <span style={{ display: "inline-block", width: "16px" }}></span>
            <a
              href="https://facebook.com/hgnc.de"
              sx={{
                color: "#fff",
                "&:hover": { color: `${palette.SECONDARY}` },
              }}
            >
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
            <span style={{ display: "inline-block", width: "16px" }}></span>
            <a
              href="https://twitter.com/hgnc_de"
              sx={{
                color: "#fff",
                "&:hover": { color: `${palette.SECONDARY}` },
              }}
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
          </Box>
        </HeaderSection>
      </Header>
      <Nav>
        <NavSection>
          <NavItem to="/">Start</NavItem>
          <NavItem to="/koelner-weg">Kölner Weg</NavItem>
          <NavItem to="/veranstaltungen">Veranstaltungen</NavItem>
          <NavItem to="/business-planner">Business Planner</NavItem>
          <NavItem to="/fit-for-invest">Fit for Invest</NavItem>
          <NavItem to="/infos">Infos</NavItem>
          <NavItem to="/matching">Matching</NavItem>
          <NavItem to="/services">Services</NavItem>
          <NavItem to="/hgnc">hgnc</NavItem>
          <NavItem to="/blog">Blog</NavItem>
        </NavSection>
      </Nav>
    </>
  )
}

export default PageHeader
